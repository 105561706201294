import React from 'react';

//TODO: Add i18n to the project, so that the user can choose between English and Spanish.

/* Particle component and dependencies: */
import Particles from 'react-tsparticles';
import particleConfig from 'components/particles';

/* Presentation (home) component and dependencies: */
import Presentation from 'components/presentation';

/* Navbar component and dependencies: */
import Navbar from 'components/navbar';

/* Projects component and dependencies: */
import Projects from 'components/projects';

/* Skills component and dependencies: */
import Skills from 'components/skills';

/* Contact component and dependencies: */
import Contact from 'components/contact';

function App(): React.ReactElement {
	return (
		<div id='home' className='App font-main'>
			<Particles id='tsparticles' options={particleConfig} />
			<Navbar />
			<Presentation />
			<Projects />
			<Skills />
			<Contact />
		</div>
	);
}

export default App;

/* Imports: */
import React from 'react';
import { faInbox } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { openLinkInNewTab } from 'helpers/urlHelpers';

const Contact: React.FC = () => {
	return (
		<div
			id='contact'
			className='bg-gray-100 p-4 grid grid-flow-row grid-cols-2 gap-2 mb-17 lg:mb-12 lg:grid lg:grid-flow-row lg:grid-cols-2 lg:gap-4 lg:w-3/4 lg:mx-auto lg:rounded-xl lg:shadow-lg cursor-default'
		>
			<h1 className='col-span-2 text-center text-2xl underline-blue font-bold uppercase text-blue-500 my-2 lg:col-span-3'>
				Contact
			</h1>
			<p className='col-span-2 text-center text-md my-2 mx-4 lg:col-span-3'>
				I&apos;m currently open to offers.
				<br />
				If you think my work ethic will fit in your company, feel free to contact me through any of the channels provided below:
			</p>
			<div
				id='contactButtons'
				className='col-span-2 flex flex-col items-center align-middle justify-center flex-grow-0 lg:grid lg:grid-flow-row lg:grid-cols-2 lg:gap-0 lg:justify-center mb-2 lg:mb-4'
			>
				<a
					className='group border border-solid border-gray-500 rounded-md p-2 w-9/12 my-1 hover:bg-blue-500 hover:text-white focus:bg-blue-500 focus:text-white transition-all duration-300 delay-0 ease-ease cursor-pointer text-center lg:col-span-1 lg:w-3/4 lg:justify-self-end lg:mr-4 lg:text-xl lg:py-4 xl:w-2/4 xl:mr-8'
					tabIndex={1}
					onClick={() => openLinkInNewTab('mailto:sergioastudillo@protonmail.com')}
					onKeyDown={(event) => {
						if (event.key === 'Enter') openLinkInNewTab('mailto:sergioastudillo@protonmail.com')
					}}
				>
					<p className='block'>E-Mail</p>
					<FontAwesomeIcon
						icon={faInbox}
						size='lg'
						className='text-custom-linkedIn group-hover:text-white group-focus:text-white transition-all duration-300 delay-0 ease-ease'
					/>
				</a>
				<a
					className='group border border-solid border-gray-500 rounded-md p-2 w-9/12 my-1 hover:bg-blue-500 hover:text-white focus:bg-blue-500 focus:text-white transition-all duration-300 delay-0 ease-ease cursor-pointer text-center lg:col-span-1 lg:w-3/4 lg:justify-self-start lg:mr-4 lg:text-xl lg:py-4 xl:w-2/4 xl:ml-8'
					tabIndex={1}
					onClick={() => openLinkInNewTab('https://www.linkedin.com/in/sergio-astudillo-gonzalez/')}
					onKeyDown={(event) => {
						if (event.key === 'Enter') openLinkInNewTab('https://www.linkedin.com/in/sergio-astudillo-gonzalez/')
					}}
				>
					<p className='block'>LinkedIn</p>
					<FontAwesomeIcon
						icon={faLinkedin}
						size='lg'
						className='text-custom-linkedIn group-hover:text-white group-focus:text-white transition-all duration-300 delay-0 ease-ease'
					/>
				</a>
			</div>
		</div>
	);
};

export default Contact;

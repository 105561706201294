/* Imports: */
import React from 'react';
import { Link } from 'react-scroll';
import { faHome, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faJs } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Navbar: React.FC = () => (
	<section className='flex justify-evenly items-center md:justify-center md:space-x-10 fixed bottom-0 w-full h-12 bg-gray-600 text-white lg:text-gray-800 z-50 lg:-top-0 lg:bg-gray-200 lg:uppercase lg:font-semibold lg:shadow-xl transition-all duration-300 delay-0 ease-ease'>
		<Link
			activeClass='active'
			to='root'
			spy
			smooth
			offset={-150}
			duration={500}
			className='cursor-pointer lg:focus:text-blue-500 lg:focus:outline-none'
			tabIndex={1}
		>
			<h1 className='hidden lg:block lg:hover:text-blue-500'>Home</h1>
			<FontAwesomeIcon className='block lg:hidden' icon={faHome} size='lg' />
		</Link>
		<Link
			activeClass='active'
			to='projects'
			spy
			smooth
			offset={0}
			duration={500}
			className='cursor-pointer lg:focus:text-blue-500 lg:focus:outline-none'
			tabIndex={1}
		>
			<p className='hidden lg:block lg:hover:text-blue-500'>Projects</p>
			<FontAwesomeIcon className='block lg:hidden' icon={faGithub} size='lg' />
		</Link>
		<Link
			activeClass='active'
			to='skills'
			spy
			smooth
			offset={0}
			duration={500}
			className='cursor-pointer lg:focus:text-blue-500 lg:focus:outline-none'
			tabIndex={1}
		>
			<p className='hidden lg:block lg:hover:text-blue-500'>Skills</p>
			<FontAwesomeIcon className='block lg:hidden' icon={faJs} size='lg' />
		</Link>
		<Link
			activeClass='active'
			to='contact'
			spy
			smooth
			offset={0}
			duration={500}
			className='cursor-pointer lg:focus:text-blue-500 lg:focus:outline-none'
			tabIndex={1}
		>
			<FontAwesomeIcon className='block lg:hidden' icon={faEnvelope} size='lg' />
			<p className='hidden lg:block lg:hover:text-blue-500'>Contact</p>
		</Link>
	</section>
);
export default Navbar;

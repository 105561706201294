/* Imports: */
import React from 'react';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { openLinkInNewTab } from 'helpers/urlHelpers';

const Projects: React.FC = () => {
	return (
		<div
			id='projects'
			className='bg-gray-100 p-4 mb-5 grid grid-flow-row grid-cols-1 gap-2 cursor-default lg:grid lg:grid-flow-row lg:grid-cols-2 lg:gap-4 lg:mb-12 lg:w-3/4 lg:mx-auto lg:rounded-xl lg:shadow-lg'
		>
			<h1 className='col-span-1 lg:col-span-2 text-center text-2xl underline-blue font-bold uppercase text-blue-500 my-2'>
				Projects
			</h1>

			<div
				id='movie-search'
				className='my-2 text-center col-span-1 w-full h-auto bg-gray-200 rounded-2xl border border-solid border-gray-500 p-4 lg:col-span-1'
			>
				<img
					src='https://camo.githubusercontent.com/883f38bc91f0ebd4d93334a71a721af4614e23774e481efb48150b3c87ea9b5f/68747470733a2f2f692e696d6775722e636f6d2f454e624e7837492e706e67'
					alt='movie-search project photo'
					className='mx-auto cursor-pointer border border-solid border-gray-500 rounded-md'
					onClick={() => openLinkInNewTab('https://github.com/SergioAstudillo/movie-search')}
				/>
				<h1 className='my-2 text-xl text-left uppercase font-semibold underline'>Movie Search</h1>
				<p className='my-2 text-md text-left'>
					Movie search app that shows coincident movies using the movieDB API, Typescript, React and
					TailwindCSS.
				</p>
				<a
					tabIndex={1}
					onClick={() => openLinkInNewTab('https://github.com/SergioAstudillo/movie-search')}
					onKeyDown={(event) => {
						if (event.key === 'Enter') openLinkInNewTab('https://github.com/SergioAstudillo/movie-search')
					}}
				>
					<FontAwesomeIcon icon={faGithub} size='2x' className='cursor-pointer' />
				</a>
			</div>

			<div
				id='daw-project-2021'
				className='my-2 text-center col-span-1 w-full h-auto bg-gray-200 rounded-2xl border border-solid border-gray-500 p-4 lg:col-span-1'
			>
				<img
					src='https://camo.githubusercontent.com/c4cf61d01798864fc44c9426f8d461a8c71c738be822826e7afac3c95fd262c2/68747470733a2f2f692e696d6775722e636f6d2f77615741697a4a2e706e67'
					alt='daw-project-2021 project photo'
					className='mx-auto cursor-pointer border border-solid border-gray-500 rounded-md'
					onClick={() => openLinkInNewTab('https://github.com/SergioAstudillo/DAW-Project-2021')}
				/>
				<h1 className='my-2 text-xl text-left uppercase font-semibold underline'>
					daw-project-2021
				</h1>
				<p className='my-2 text-md text-left'>
					Full-stack web app (SPA) made for the final degree project using React, Tailwind CSS,
					Express, MongoDB and Nodemailer (with some handlebars - HBS).
				</p>
				<a
					tabIndex={1}
					onClick={() => openLinkInNewTab('https://github.com/SergioAstudillo/DAW-Project-2021')}
					onKeyDown={(event) => {
						if (event.key === 'Enter') openLinkInNewTab('https://github.com/SergioAstudillo/DAW-Project-2021')
					}}
				>
					<FontAwesomeIcon icon={faGithub} size='2x' className='cursor-pointer' />
				</a>
			</div>

			<div
				id='signup-formJS'
				className='my-2 text-center col-span-1 lg:col-span-2 w-full h-auto bg-gray-200 rounded-2xl border border-solid border-gray-500 p-4 transition-all duration-300 delay-0 ease-ease lg:mb-4 lg:mt-0 xl:w-2/3 xl:mx-auto'
			>
				<img
					src='https://camo.githubusercontent.com/46bed4f30e59741326d6f18df14b29d0aa952bf3396aa3aae748ae27fdf2bd00/68747470733a2f2f696d6775722e636f6d2f526e673756784a2e6a706567'
					alt='signup-form project photo'
					className='mx-auto cursor-pointer border border-solid border-gray-500 rounded-md'
					onClick={() => openLinkInNewTab('https://github.com/SergioAstudillo/signupForm-JS')}
				/>
				<h1 className='my-2 text-xl text-left uppercase font-semibold underline'>
					Signup + Login form
				</h1>
				<p className='my-2 text-md text-left'>
					Signup and login web (with password and email validation in both frontend and backend).
					The password is encripted and the user can only change his own password. Made using
					Handlebars, SaSS/ScSS, JS, Node.JS, MySQL.
				</p>
				<a
					tabIndex={1}
					onClick={() => openLinkInNewTab('https://github.com/SergioAstudillo/signupForm-JS')}
					onKeyDown={(event) => {
						if (event.key === 'Enter') openLinkInNewTab('https://github.com/SergioAstudillo/signupForm-JS')
					}}
				>
					<FontAwesomeIcon icon={faGithub} size='2x' className='cursor-pointer' />
				</a>
			</div>
		</div>
	);
};

export default Projects;

/* Imports: */
import React from 'react';
import Typed, { TypedOptions } from 'typed.js';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { openLinkInNewTab } from 'helpers/urlHelpers';

const Presentation: React.FC = () => {
	/* Typed.JS didn't take in account TS + React so we have to use <any> on typedElement but in reality it should be <string | Element> */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const typedElement = React.useRef<any>(null);
	const typedText = React.useRef<Typed>();

	React.useEffect(() => {
		const options: TypedOptions = {
			strings: ['Welcome!', ''],
			typeSpeed: 50,
			backSpeed: 50,
			loop: true,
			smartBackspace: true
		};
		typedText.current = new Typed(typedElement.current, options);

		return () => {
			if (typedText.current !== undefined) {
				typedText.current.destroy();
			}
		};
	});

	return (
		<div
			id='about'
			className='bg-gray-100 mb-5 p-4 lg:w-3/4 cursor-default lg:mx-auto lg:my-12 lg:mt-20 lg:rounded-xl lg:shadow-lg'
		>
			<div id='typedText' className='block mx-auto text-center my-4 h-6'>
				<p
					className='inline-block text-2xl uppercase text-blue-500 underline-blue font-bold'
					ref={typedElement}
				/>
			</div>
			<img
				src='media/img/CVPhoto-rounded.png'
				alt='Personal photo'
				className='border border-solid mt-6 border-gray-500 rounded-half h-36 w-36 mx-auto'
			/>
			<h1 className='text-md lg:text-xl my-4 text-center'>
				<p className='mb-3'>My name is Sergio Astudillo, and I am a Full-Stack Developer.</p>
				<p className='mx-4'>
				I consider myself a reliable team performer, possessing a steadfast commitment to continuous learning and the mastery of cutting-edge technologies utilized in my daily work. My passion for creative problem-solving drives me to deliver innovative solutions for my clients and achieve excellence in my field.
				</p>
			</h1>
			<div
				id='buttons'
				className='flex flex-col items-center align-middle justify-center flex-grow-0 lg:grid lg:grid-flow-column lg:grid-cols-3 lg:gap-4 xl:gap-0 lg:items-center lg:justify-center lg:justify-items-center lg:mx-8'
			>
				<button
					className='border border-solid border-gray-500 rounded-md p-2 w-9/12 my-1 hover:bg-blue-500 hover:text-white focus:bg-blue-500 focus:text-white transition-all duration-300 delay-0 ease-ease lg:w-full xl:w-3/4 lg:justify-self-end'
					onClick={() => openLinkInNewTab('/media/cv/cv.pdf')}
					tabIndex={1}
				>
					<p className='mr-2 inline-block'>Resume</p>
					<FontAwesomeIcon icon={faFileExport} size='lg' />
				</button>
				<button
					className='border border-solid border-gray-500 rounded-md p-2 w-9/12 my-1 hover:bg-blue-500 hover:text-white focus:bg-blue-500 focus:text-white transition-all duration-300 delay-0 ease-ease lg:w-full xl:w-3/4'
					onClick={() => openLinkInNewTab('https://github.com/SergioAstudillo')}
					tabIndex={1}
				>
					<p className='mr-2 inline-block'>Github</p>
					<FontAwesomeIcon icon={faGithub} size='lg' />
				</button>
				<button
					className='border border-solid border-gray-500 rounded-md p-2 w-9/12 my-1 hover:bg-blue-500 hover:text-white focus:bg-blue-500 focus:text-white transition-all duration-300 delay-0 ease-ease lg:w-full xl:w-3/4 lg:justify-self-start'
					onClick={() => openLinkInNewTab('https://www.linkedin.com/in/sergio-astudillo-gonzalez/')}
					tabIndex={1}
				>
					<p className='mr-2 inline-block'>LinkedIn</p>
					<FontAwesomeIcon icon={faLinkedin} size='lg' />
				</button>
			</div>
		</div>
	);
};

export default Presentation;

/* Imports: */
import React from 'react';
import { openLinkInNewTab } from 'helpers/urlHelpers';

//TODO: Download icons and so we can import them here instead of using the link

const Skills: React.FC = () => {
	return (
		<div
			id='skills'
			className='bg-gray-100 p-4 mb-5 grid grid-flow-row grid-cols-2 gap-2 cursor-default lg:grid lg:grid-flow-row lg:grid-cols-4 lg:gap-4 lg:mb-12 lg:w-3/4 lg:mx-auto lg:rounded-xl lg:shadow-lg'
		>
			<h1 className='col-span-2 text-center text-2xl underline-blue font-bold uppercase text-blue-500 my-2 lg:col-span-4'>
				Skills
			</h1>
			<div id='html5' className='text-center w-full h-24'>
				<img
					src='https://www.vectorlogo.zone/logos/w3_html5/w3_html5-ar21.svg'
					alt='HTML5 Icon'
					className='mx-auto cursor-pointer'
					onClick={() => openLinkInNewTab('https://developer.mozilla.org/en-US/docs/Web/HTML')}
					onKeyDown={(event) => {
						if (event.key === 'Enter') openLinkInNewTab('https://developer.mozilla.org/en-US/docs/Web/HTML')
					}}
					tabIndex={1}
				/>
				<h1 className='my-2 text-md'>HTML 5</h1>
			</div>
			<div id='sass' className='text-center w-full h-24'>
				<img
					src='https://www.vectorlogo.zone/logos/sass-lang/sass-lang-ar21.svg'
					alt='SaSS Icon'
					className='mx-auto cursor-pointer'
					onClick={() => openLinkInNewTab('https://sass-lang.com/')}
					onKeyDown={(event) => {
						if (event.key === 'Enter') openLinkInNewTab('https://sass-lang.com/')
					}}
					tabIndex={1}
				/>
				<h1 className='my-2 text-md'>SaSS</h1>
			</div>
			<div id='tailwindcss' className='text-center w-full h-24'>
				<img
					src='https://www.vectorlogo.zone/logos/tailwindcss/tailwindcss-icon.svg'
					alt='TailwindCSS'
					className='mx-auto cursor-pointer'
					onClick={() => openLinkInNewTab('https://tailwindcss.com/')}
					onKeyDown={(event) => {
						if (event.key === 'Enter') openLinkInNewTab('https://tailwindcss.com/')
					}}
					tabIndex={1}
				/>
				<h1 className='my-2 text-md'>Tailwind CSS</h1>
			</div>
			<div id='js' className='text-center w-full h-24'>
				<img
					src='https://cdn.iconscout.com/icon/free/png-256/javascript-2752148-2284965.png'
					alt='JS Icon'
					width='65rem'
					height='65rem'
					className='mx-auto cursor-pointer'
					onClick={() => openLinkInNewTab('https://developer.mozilla.org/en-US/docs/Web/JavaScript')}
					onKeyDown={(event) => {
						if (event.key === 'Enter') openLinkInNewTab('https://developer.mozilla.org/en-US/docs/Web/JavaScript')
					}}
					tabIndex={1}
				/>
				<h1 className='my-2 text-md'>Javascript</h1>
			</div>
			<div id='ts' className='text-center w-full h-24'>
				<img
					src='https://www.vectorlogo.zone/logos/typescriptlang/typescriptlang-icon.svg'
					alt='TS Icon'
					className='mx-auto cursor-pointer'
					onClick={() => openLinkInNewTab('https://www.typescriptlang.org/')}
					onKeyDown={(event) => {
						if (event.key === 'Enter') openLinkInNewTab('https://www.typescriptlang.org/')
					}}
					tabIndex={1}
				/>
				<h1 className='my-2 text-md'>Typescript</h1>
			</div>
			<div id='react' className='text-center w-full h-24'>
				<img
					src='https://www.iconninja.com/files/866/540/1/react-js-react-js-logo-icon.svg'
					alt='React Icon'
					width='60rem'
					height='60rem'
					className='mx-auto cursor-pointer'
					onClick={() => openLinkInNewTab('https://reactjs.org/')}
					onKeyDown={(event) => {
						if (event.key === 'Enter') openLinkInNewTab('https://reactjs.org/')
					}}
					tabIndex={1}
				/>
				<h1 className='my-2 text-md'>React</h1>
			</div>
			<div id='vue' className='text-center w-full h-24'>
				<img
					src='https://www.vectorlogo.zone/logos/vuejs/vuejs-icon.svg'
					alt='Vue Icon'
					width='60rem'
					height='60rem'
					className='mx-auto cursor-pointer'
					onClick={() => openLinkInNewTab('https://vuejs.org/')}
					onKeyDown={(event) => {
						if (event.key === 'Enter') openLinkInNewTab('https://vuejs.org/')
					}}
					tabIndex={1}
				/>
				<h1 className='my-2 text-md'>Vue</h1>
			</div>
			<div id='testcafe' className='text-center w-full h-24'>
				<img
					src='https://raw.githubusercontent.com/vscode-icons/vscode-icons/5a7cb2173c87167e9aa88ac4b0f5301e6eef975c/icons/file_type_testcafe.svg'
					alt='Testcafe Icon'
					width='60rem'
					height='60rem'
					className='mx-auto cursor-pointer'
					onClick={() => openLinkInNewTab('https://testcafe.io/')}
					onKeyDown={(event) => {
						if (event.key === 'Enter') openLinkInNewTab('https://testcafe.io/')
					}}
					tabIndex={1}
				/>
				<h1 className='my-2 text-md'>TestCafe</h1>
			</div>
			<div id='jira' className='text-center w-full h-24'>
				<img
					src='https://www.vectorlogo.zone/logos/atlassian_jira/atlassian_jira-icon.svg'
					alt='Jira Icon'
					width='60rem'
					height='60rem'
					className='mx-auto cursor-pointer'
					onClick={() => openLinkInNewTab('https://www.atlassian.com/es/software/jira')}
					onKeyDown={(event) => {
						if (event.key === 'Enter') openLinkInNewTab('https://www.atlassian.com/es/software/jira')
					}}
					tabIndex={1}
				/>
				<h1 className='my-2 text-md'>Jira</h1>
			</div>
			<div id='git' className='text-center w-full h-24'>
				<img
					src='https://www.vectorlogo.zone/logos/git-scm/git-scm-ar21.svg'
					alt='git Icon'
					className='mx-auto cursor-pointer'
					onClick={() => openLinkInNewTab('https://git-scm.com/')}
					onKeyDown={(event) => {
						if (event.key === 'Enter') openLinkInNewTab('https://git-scm.com/')
					}}
					tabIndex={1}
				/>
				<h1 className='my-2 text-md'>Git</h1>
			</div>
			<div id='github' className='text-center w-full h-24'>
				<img
					src='https://www.vectorlogo.zone/logos/github/github-icon.svg'
					alt='GitHub Icon'
					className='mx-auto cursor-pointer'
					onClick={() => openLinkInNewTab('https://github.com/')}
					onKeyDown={(event) => {
						if (event.key === 'Enter') openLinkInNewTab('https://github.com/')
					}}
					tabIndex={1}
				/>
				<h1 className='my-2 text-md'>Github</h1>
			</div>
			<div id='gitlab' className='text-center w-full h-24'>
				<img
					src='https://www.vectorlogo.zone/logos/gitlab/gitlab-icon.svg'
					alt='Gitlab Icon'
					className='mx-auto cursor-pointer'
					onClick={() => openLinkInNewTab('https://gitlab.com/')}
					onKeyDown={(event) => {
						if (event.key === 'Enter') openLinkInNewTab('https://gitlab.com/')
					}}
					tabIndex={1}
				/>
				<h1 className='my-2 text-md'>Gitlab</h1>
			</div>
			<div id='nodejs' className='text-center w-full h-24'>
				<img
					src='https://www.vectorlogo.zone/logos/nodejs/nodejs-ar21.svg'
					alt='NodeJS Icon'
					className='mx-auto cursor-pointer'
					onClick={() => openLinkInNewTab('https://nodejs.org/en/')}
					onKeyDown={(event) => {
						if (event.key === 'Enter') openLinkInNewTab('https://nodejs.org/en/')
					}}
					tabIndex={1}
				/>
				<h1 className='my-2 text-md'>Node.JS</h1>
			</div>
			<div id='express' className='text-center w-full h-24'>
				<img
					src='https://www.vectorlogo.zone/logos/expressjs/expressjs-ar21.svg'
					alt='Express Icon'
					className='mx-auto cursor-pointer'
					onClick={() => openLinkInNewTab('http://expressjs.com/')}
					onKeyDown={(event) => {
						if (event.key === 'Enter') openLinkInNewTab('http://expressjs.com/')
					}}
					tabIndex={1}
				/>
				<h1 className='my-2 text-md'>Express</h1>
			</div>
			<div id='mongoDB' className='text-center w-full h-24'>
				<img
					src='https://www.vectorlogo.zone/logos/mongodb/mongodb-ar21.svg'
					alt='MongoDB Icon'
					className='mx-auto cursor-pointer'
					onClick={() => openLinkInNewTab('https://www.mongodb.com/')}
					onKeyDown={(event) => {
						if (event.key === 'Enter') openLinkInNewTab('https://www.mongodb.com/')
					}}
					tabIndex={1}
				/>
				<h1 className='my-2 text-md'>Mongo DB</h1>
			</div>
			<div id='mySQL' className='text-center w-full h-24 flex-column flex-wrap'>
				<img
					src='https://www.vectorlogo.zone/logos/mysql/mysql-ar21.svg'
					alt='mySQL Icon'
					className='mx-auto cursor-pointer'
					onClick={() => openLinkInNewTab('https://www.mysql.com/')}
					onKeyDown={(event) => {
						if (event.key === 'Enter') openLinkInNewTab('https://www.mysql.com/')
					}}
					tabIndex={1}
				/>
				<h1 className='my-2 text-md'>My SQL</h1>
			</div>
		</div>
	);
};

export default Skills;
